import SplitLandingHero from "./SplitLandingHero";
import TierPrices from "./TierPrices";
import TelaportBanner from "./TelaportBanner";
import SplitLandingHeroFriendly from "./SplitLandingHeroFriendly";
import Store from "./Store";
import { Container, Row } from "react-bootstrap";

const owner = window.location.hostname === "localhost"
    ? ""
    : "7d3ee42c-800e-4145-baa2-23a73e4f87c6";

export default function Landing() {
    return (
        <div className="App flex bg-light">
            <TelaportBanner />
            <Container className="mt-3">
                <Row className="text-center my-3">
                    <h1><strong>Marketplace</strong></h1>
                    <h4>The World's Safest Luxury Trading App</h4>
                </Row>
                <Store
                    owner={owner}
                    displayLimit={6}
                    ordering="random"
                    embed
                    randomize
                />
            </Container>
            <TierPrices />
            <SplitLandingHero />
        </div>
    );
}