import './TierPrices.css'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../utils/constants';
import { Authentication } from '../types';

function TierPrices() {
  const navigate = useNavigate();

  const TelaportButton = () => {
    return (
      <Button variant="primary" className="mt-auto"
        onClick={() =>
          Authentication.load().hasToken
            ? navigate(URLS.ORDER_EDIT)
            : navigate(URLS.USER_SIGNUP)
        }
      >
        TELAPORT
      </Button>
    );
  }

  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-md-center josefin-sans">
        <h2 className="text-center mb-4"><strong>Our Pricing</strong></h2>
        <h4 className="text-center mb-4 ">These are your choices you can make when you trade goods with Telaport.</h4>
        <Col md={15}>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                {/* <Card.Img variant="top" src="/path-to-your-logo.png" /> */}
                <Card.Body className="flex-column d-flex">
                  <Card.Text className="text-primary text-center h6"><strong>Lite</strong></Card.Text>
                  <Card.Title className="text-center mb-0 display-6"><strong>$19</strong></Card.Title>
                  <Card.Text className="text-secondary text-center"><strong>Telaport</strong></Card.Text>
                  <Card.Text className="checkmark-list">
                    <ul className="text-body price-list checkmark-list">
                      <li>Wait for both packages to arrive at Telaport.</li>
                      <li>Keep packages at Telaport for up to 7 days.</li>
                      <li>Pick your shipping options to Telaport home.</li>
                    </ul>
                  </Card.Text>
                  <TelaportButton />
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                {/* <Card.Img variant="top" src="/path-to-your-logo.png" /> */}
                <Card.Body className="flex-column d-flex">
                  <Card.Text className="text-primary text-center h6"><strong>Basic</strong></Card.Text>
                  <Card.Title className="text-center mb-0 display-6"><strong>$49</strong></Card.Title>
                  <Card.Text className="text-secondary text-center"><strong>Telaport & Verify</strong></Card.Text>
                  <Card.Text className="checkmark-list">
                    <ul>
                      <li>Telaport will <strong>check & compare</strong> package contents to order description & pictures.</li>
                      <li>Telaport will safely ship your package back to you if it isn't verified.</li>
                      <li>Includes all features of the Lite service.</li>
                    </ul>
                  </Card.Text>
                  <TelaportButton />
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                {/* <Card.Img variant="top" src="/path-to-your-logo.png" /> */}
                <Card.Body className="flex-column d-flex">
                  <Card.Text className="text-primary text-center h6"><strong>Pro</strong></Card.Text>
                  <Card.Title className="text-center mb-0 display-6"><strong>$99</strong></Card.Title>
                  <Card.Text className="text-secondary text-center"><strong>Telaport & Photos</strong></Card.Text>
                  <Card.Text className="checkmark-list">
                    <ul>
                      <li>Telaport will <strong>send photographs</strong> and <strong>check</strong> package contents according to order description & pictures.</li>
                      <li>Includes all features of Basic service.</li>
                      <li>Includes all features of Lite service.</li>
                    </ul>
                  </Card.Text>
                  <TelaportButton />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>

            <Col md={4} className="mb-4 mx-auto">
              <Card className="h-100 shadow-sm">
                {/* <Card.Img variant="top" src="/path-to-your-logo.png" /> */}
                <Card.Body className="flex-column d-flex">
                  <Card.Text className="text-primary text-center h6"><strong>Elite</strong></Card.Text>
                  <Card.Title className="text-center mb-0 display-6"><strong>Contact Us</strong></Card.Title>
                  <Card.Text className="text-secondary text-center"><strong>Telaport & Insure</strong></Card.Text>
                  <Card.Text className="checkmark-list">
                    <ul>
                      <li>An Exclusive Tier for those trading <strong>ELITE</strong> items.</li>
                      <li>Provides custom insurance for <strong>professionals</strong> who trade high value items.</li>
                      <li>Includes all the features of Lite, Basic, and Pro tiers.</li>
                    </ul>
                  </Card.Text>
                  {/* <TelaportButton /> */}
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default TierPrices;
