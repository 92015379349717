import './Team.css';
import './TierPrices.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

export default function Team(): JSX.Element {
    return (
        <Container fluid className="hero-section mt-4 mb-5">
            <h1 className="mb-4 text-center">Meet Telaport.</h1>
            <Row className="justify-content-md-center josefin-sans" style={{ minHeight: '75vh' }}>
                <Col md={3} className="hero-left d-flex flex-column align-items-center mb-5">
                    <Card className="h-70 shadow-sm">
                        <Card.Img variant="top" src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/me_2_cropped.jpg" className="card-image-top-fit" />
                        <Card.Body className="flex-column d-flex">
                            <Card.Title className="text-center mb-0 display-6"><strong>Antonio LaPlaca</strong></Card.Title>
                            <Card.Text className="text-primary text-center h5"><strong>Founder / CEO of Telaport</strong></Card.Text>
                            {/* <Card.Text className="">
                                <ul className="text-body price-list bullet-list">
                                    <li className="lead">I founded Telaport to FINALLY solve the problem of trading, trusting, and retaining value of your goods online, without the intervention of large company's value based fees or unnecessary taxes.</li>
                                    <li className="lead">Graduated from the University of Toronto with an Honours Bachelor of Science in Computer Science and Geographical Information Systems.</li>
                                    <li className="lead">An Absolutely avid collector of sealed video games, antiquarian books, historical items, and numismatics.</li>
                                </ul>
                            </Card.Text> */}
                            <Button variant="primary" className="mt-auto" href="https://www.antoniolaplaca.com/">Antonio LaPlaca's Website</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3} className="hero-left d-flex flex-column align-items-center mb-5">
                    <Card className="h-70 shadow-sm">
                        <Card.Img variant="top" src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/pfp-will.jpg" className="card-image-top-fit" />
                        <Card.Body className="flex-column d-flex">
                            <Card.Title className="text-center mb-0 display-6"><strong>William Grim</strong></Card.Title>
                            <Card.Text className="text-primary text-center h5"><strong>Co-Founder / Lead Engineer</strong></Card.Text>
                            {/* <Card.Text>
                                <ul className="text-body price-list bullet-list">
                                    <li className="lead">With over 17 years of software development experience, William played the major role in developing Telaports Infrastructure.</li>
                                    <li className="lead">Graduated from Southern Illinois University with a Masters in Computer Science.</li>
                                    <li className="lead">Has past experience with ROBLOX, Morgan Stanley, Signal, and other Tech Startups.</li>
                                </ul>
                            </Card.Text> */}
                            {/* <Button variant="primary" className="mt-auto" href="https://www.linkedin.com/in/grimwm">William Grim's LinkedIn</Button> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
