import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer className="mt-auto py-3 bg-dark">
      <Container fluid className="text-center">
        <span className="text-white">
          Copyright (C) 2025 Telaport™. 2023-2025 Telaport Inc. All rights reserved.
        </span>
      </Container>
    </footer>
  );
}

export default Footer;
