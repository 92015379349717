import "./Logo.css";

export const LOGO_URL = "https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/Banner_Logo.png";

export type LogoProperties = React.ImgHTMLAttributes<HTMLImageElement> & {
  spin?: boolean;
}

export default function Logo(
  { className, spin, alt, src, ...remaining }: LogoProperties
) {
  const classes = className ? className.split(" ") : [];

  classes.push("logo");
  if (spin) {
    classes.push("spin");
  }
  if (className) {
    classes.push(className);
  }

  return (
    <img
      src={src ? src : LOGO_URL}
      className={classes.join(" ")}
      alt={alt || "logo"}
      {...remaining}
    />
  );
}
